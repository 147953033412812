import { Seo } from '@wwtr/components/layout';
import { Button, Container, Heading } from '@wwtr/components/ui';
import React from 'react';
import NextLink from 'next/link';

function App404() {
  const seoOptions = {
    noindex: true,
    nofollow: true,
  };
  return (
    <>
      <Seo title={'Foutmelding'} {...seoOptions} />
      <Container>
        <div className="grid lg:grid-cols-8 xl:grid-cols-12">
          <div className="lg:col-start-2 xl:col-start-3 lg:col-span-6 xl:col-span-8 min-h-70vh flex flex-col justify-center items-center space-y-8">
            <Heading
              variant="gray-800"
              level={1}
              className="font-bold text-center"
            >
              Pagina niet gevonden.
            </Heading>
            <p className="text-gray-700 leading-relaxed lg:text-xl lg:leading-relaxed mb-16 text-center">
              Het lijkt erop dat de door u opgevraagde pagina niet (meer)
              bestaat. Ga terug naar de homepagina of probeer de zoekfunctie om
              uw informatie te vinden.
            </p>
            <NextLink href={'/'} passHref>
              <Button as="a" color={'primary'}>
                Ga terug naar home
              </Button>
            </NextLink>
          </div>
        </div>
      </Container>
    </>
  );
}

export default App404;
